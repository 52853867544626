<!-- stack chart combined with line chart -->
<template>
  <div class="chart-container">
    <p class="d-none">{{ chartData.series }}</p>
    <three-dimensional-chart
      ref="threeDimensionalChart"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
export default {
  name: "ThreeDimensionalChart",
  components: {
    threeDimensionalChart: Chart,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    (this.chartOptions.colors = this.chartData.color),
      (this.chartOptions.series = this.chartData.data);
  },
  beforeUpdate() {
    this.chartOptions.series = this.chartData.data;
  },
  data() {
    return {
      chartOptions: {
        chart: {
          zoomType: "xy",
          width: this.chartData.width,
          height: this.chartData.height,
          scrollablePlotArea: {
            minWidth: this.chartData.scrollableWidth
              ? this.chartData.scrollableWidth
              : 0,
            opacity: 1,
          },
        },
        title: this.chartData.title,
        subtitle: this.chartData.subtitle,
        xAxis: this.chartData.xAxis,
        yAxis: this.chartData.yAxis,
        tooltip: this.chartData.tooltip,
        credits: {
          enabled: false,
        },
        plotOptions: this.chartData.plotOptions,
        colors: this.chartData.color,
        series: this.chartData.data,
        legend: this.chartData.legend,
      },
    };
  },
  mounted() {
    this.chartOptions.series = this.chartData.data;
    if (this.chartOptions.xAxis.crosshair.events === undefined) {
      this.chartOptions.xAxis.crosshair = {};
      this.chartOptions.xAxis.crosshair.enabled = false;
    }
    const theChart = this.$refs.threeDimensionalChart;
    this.chartOptions.tooltip = {
      shared: true,
      formatter: function (tooltip) {
        theChart.columnIndex = theChart.options.xAxis.categories.indexOf(
          this.x
        );
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    };
    var self = this;
    this.chartOptions.xAxis.crosshair.events = {
      click: function () {
        const category =
          theChart.options.xAxis.categories[theChart.columnIndex];
        self.$emit("columnClick", category);
      },
    };
  },
};
</script>
<style scoped>
.chart-container {
  max-height: 588px;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 0 0 30px 0; */
  background: #fff;
}

.highcharts-tooltip > span {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px !important;
  float: left;
  justify-content: flex-start;
}
.highcharts-markers {
  display: none;
}

.make-bold {
  font-weight: 900;
}
</style>
