<template>
  <div style="margin-top: 120px; overflow: hidden" v-if="this.$route.name === 'AIMO-Platform-Uniliver-Future'">
    <Forecasting ref="planModal" @updatePlan="updatePlan" @deleteModel="deleteModel" />

    <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <div class="d-flex flex-row justify-content-end" style="height: 40%">
      <div class="pt-4">
        <w-white-dropdown :options="brandDropdown" :placeHolder="'Select'" :selectedOption="selectedBrand"
          :labelText="'Brand'" class="mr-3" @input="selectBrand($event)"></w-white-dropdown>
      </div>

      <div class="pt-4">
        <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
          :labelText="'KPI'" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
      </div>
    </div>
    <div class="container-fluid">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <!-- <h5 class="sales-growth">Define Budget</h5> -->
            <w-tab :tabName="activeSaleGrowthTab" :tabs="saleGrowthtabs" @activeTab="changeSaleGrowthTab($event)">
            </w-tab>
          </div>
        </div>

        <SalesGrowthUL @showOptimizeButton="checkForValidations($event)"
          v-if="activeSaleGrowthTab === 'Budget Based Optimization'" :sliderValue="sliderRevenueValue"
          :data="revenueData" :activeTab="activeSaleGrowthTab" :selectedBrandKPI="selectedBrand.text"
          @budget="getBudget($event)" @totalBudgets="getTotalBudget($event)" />
        <SalesGrowthUL @tableData="summaryTableData($event)" @viewDetails="showViewDetails"
          v-if="activeSaleGrowthTab === 'Forecasting'" :sliderValue="sliderRevenueValue" :data="revenueData"
          :activeTab="activeSaleGrowthTab" :selectedBrandKPI="selectedBrand.text" @budget="getBudget($event)"
          @totalBudgets="getTotalBudget($event)" />
      </div>

      <div v-if="activeSaleGrowthTab === 'Budget Based Optimization'" class="row d-none">
        <div class="content-wrapper">
          <div class="col-md-12">
            <!--Template here-->

            <div style="margin-left: 30px; margin-right: 30px">
              <b-row>
                <b-col>
                  <div class="card-header font-large ProximaNovaBold content-center" style="
                      justify-content: space-between;
                      background: transparent;
                      border: 0px !important;
                    ">
                    <div>Download</div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <input v-model="numOfWeeks" class="input-box m-0" style="width: 300px; font-size: 16px"
                    placeholder="Enter number of weeks" @input="selectedWeeks($event)" />
                </b-col>
                <b-col>
                  <w-calendar :selectedDate="'2022-05-19'" :placeHolder="'Select'" :labelText="'Date'" class=""
                    @getDate="selectForecastingDate($event)" />
                </b-col>
                <b-col>
                  <w-button style="width: 256px; margin-right: 50px" :buttonLoader="'normal'" :buttonText="'Download'"
                    @buttonClicked="handleTemplateDownload">
                  </w-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col style="margin-top: 80px">
                  <div class="card-header font-large ProximaNovaBold content-center" style="
                      justify-content: space-between;
                      background: transparent;
                      border: 0px !important;
                    ">
                    <div>Scenario # {{ numOfScenario }}</div>
                  </div>
                </b-col>
              </b-row>
              <div class="d-none" v-for="(scenario, index) in scenarioDetails" :key="index">
                <b-row>
                  <b-col>
                    <input v-model="scenarioName" class="input-box mt-5" style="width: 256px; font-size: 16px"
                      placeholder="Enter scenario name" @input="getScenarioName($event, index)" />
                  </b-col>
                  <b-col>
                    <div style="width: 330px" class="px-0 pb-5 d-flex justify-center input-types">
                      <input type="file" id="actual-btn" ref="fileInput" accept=".csv"
                        @change="onFileChange($event, index)" hidden />
                      <label class="file-label" for="actual-btn" v-if="seen">Choose File</label>
                      <span id="file-chosen" ref="file-chosen" class="d-flex"
                        style="justify-content: space-between; font-size: 15px">
                        {{ file }}
                        <label v-if="!seen" class="remove-label mr-5"
                          @click="clearFileUploader($event, index)">Remove</label>
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-row class="mt-3">
                <b-col>
                  <input v-model="scenarioName" class="input-box m-0" style="width: 256px; font-size: 16px"
                    placeholder="Enter scenario name" @input="getScenarioName($event)" />
                </b-col>
                <b-col>
                  <div style="width: 330px" class="px-0 pb-5 d-flex justify-center input-types">
                    <input type="file" id="actual-btn" ref="fileInput" accept=".csv" @change="onFileChange" hidden />
                    <label class="file-label" for="actual-btn" v-if="seen">Choose File</label>
                    <span id="file-chosen" ref="file-chosen" class="d-flex"
                      style="justify-content: space-between; font-size: 15px">
                      {{ file }}
                      <label v-if="!seen" class="remove-label mr-5" @click="clearFileUploader">Remove</label>
                    </span>
                  </div>
                </b-col>

                <b-col>
                  <w-button v-if="showNextConstrint" style="width: 206px; margin-right: 50px" :buttonLoader="'normal'"
                    :buttonText="'Upload'" @buttonClicked="handleUploadTemplate">
                  </w-button>
                </b-col>
                <b-col v-if="isViewDetails">
                  <div class="d-flex flex-row">
                    <div class="sliderIcon" @click="handleAdditionSceario">
                      <div>
                        <i class="fas fa-plus px-2" :style="{ color: rangeColor }"></i>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-if="isViewDetails">
                <w-button style="width: 206px; float: right; margin-right: 40px" :buttonLoader="'normal'"
                  :buttonText="'View Details'" @buttonClicked="handleViewDetails">
                </w-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showConstraints" class="content-wrapper mt-5" style="height: 550px">
        <!-- <details> -->
        <div class="Additional-details">Constraints</div>
        <div v-show="constraintOne" class="align-items-center additonal-detail mt-5"
          v-for="(detail, index) in levelsDetailsConstraintOne" :key="index">
          <!-- <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div> -->
          <div class="col-lg-4 col-md-4 col-12">
            <h6 class="d-flex ProductNew justify-center">
              {{ detail.name }}
            </h6>
            <div class="d-flex justify-center mt-4">
              <div class="w-100">
                <w-dropdown :options="detail.dropdownList" :placeHolder="'Select'" :labelText="''" class="" @input="
  selectedKPiOne($event, index);
getLevelDropdownDetails($event, index);
                "></w-dropdown>
                <!-- {{ levelsDetailsConstraintOne[1].dropdownList }} -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-3 col-12" v-if="detail.name != 'Constraint #1'">
            <div class="mt-5">
              <b-form-input class="input-wrapper" v-model="detail.value" type="number" placeholder="Value in thousands">
              </b-form-input>
            </div>
          </div>
        </div>

        <div v-show="constraintTwo" class="align-items-center additonal-detail mt-5"
          v-for="(detail, index) in levelsDetailsConstraintTwo" :key="index">
          <!-- <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div> -->
          <div class="col-md-4 col-12 mt-5">
            <h6 class="d-flex ProductNew justify-center">
              {{ detail.name }}
            </h6>
            <div class="d-flex justify-center mt-4">
              <div class="w-100">
                <w-dropdown :options="detail.dropdownList" :placeHolder="'Select'" :labelText="''" class="" @input="
  selectedKPiTwo($event, index);
getLevelDropdownDetails($event, index);
                "></w-dropdown>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 d-none">
            <div class="date-wraper d-flex w-100">
              <div class="d-flex align-items-center w-100">
                <!--Do here-->
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 mt-5" v-if="detail.name != 'Constraint #2'">
            <div class="mt-5">
              <b-form-input class="input-wrapper" v-model="detail.value" type="number" placeholder="Value in thousands">
              </b-form-input>
            </div>
          </div>
        </div>

        <div v-show="constraintThree" class="align-items-center additonal-detail mt-5"
          v-for="(detail, index) in levelsDetailsConstraintThree" :key="index">
          <!-- <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div> -->
          <div class="col-md-4 col-12 mt-5">
            <h6 class="d-flex ProductNew justify-center">
              {{ detail.name }}
            </h6>
            <div class="d-flex justify-center mt-4">
              <div class="w-100">
                <w-dropdown :options="detail.dropdownList" :placeHolder="'Select'" :labelText="''" class="" @input="
  selectedKPiThree($event, index);
getLevelDropdownDetails($event, index);
                "></w-dropdown>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 d-none">
            <div class="date-wraper d-flex w-100">
              <div class="d-flex align-items-center w-100">
                <!--Do here-->
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 mt-5" v-if="detail.name != 'Constraint #3'">
            <div class="mt-5">
              <b-form-input class="input-wrapper" v-model="detail.value" type="number" placeholder="Value in thousands">
              </b-form-input>
            </div>
          </div>
        </div>

        <div v-show="constraintFour" class="align-items-center additonal-detail mt-5"
          v-for="(detail, index) in levelsDetailsConstraintFour" :key="index">
          <!-- <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div> -->
          <div class="col-md-4 col-12 mt-5">
            <h6 class="d-flex ProductNew justify-center">
              {{ detail.name }}
            </h6>
            <div class="d-flex justify-center mt-4">
              <div class="w-100">
                <w-dropdown :options="detail.dropdownList" :placeHolder="'Select'" :labelText="''" class="" @input="
  selectedKPiFour($event, index);
getLevelDropdownDetails($event, index);
                "></w-dropdown>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 d-none">
            <div class="date-wraper d-flex w-100">
              <div class="d-flex align-items-center w-100">
                <!--Do here-->
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 mt-5" v-if="detail.name != 'Constraint #4'">
            <div class="mt-5">
              <b-form-input class="input-wrapper" v-model="detail.value" type="number" placeholder="Value in thousands">
              </b-form-input>
            </div>
          </div>
        </div>
        <div style="width: 60px; border-radius: 8px; margin-top: 180px" class="ml-4 d-none">
          <div class="sliderIcon mr-2" @click="handleConstraints">
            <div>
              <i class="fas fa-plus px-2" :style="{ color: rangeColor }"></i>
            </div>
          </div>
        </div>
        <!-- </details> -->
      </div>

      <div v-if="showSummaryDetails" class="content-wrapper mt-5">
        <details open>
          <Summary class="Additional-details">Summary</Summary>
          <div class="d-flex justify-content-end">
            <w-white-dropdown style="width: 500px" :options="scenarioDropdown" :placeHolder="'Select'"
              :selectedOption="selectedScenario" :labelText="'Scenario'" class="mr-3" @input="selectScenario($event)">
            </w-white-dropdown>
          </div>

          <b-container style="margin-top: 80px">
            <b-row>
              <b-col>
                <div class="dataCard">
                  <div class="row">
                    <div class="col-md-8 col-8 pr-0">
                      <div class="d-flex justify-content-between">
                        <div class="Last-year-title py-2">Total Weeks</div>
                        <span class="line"></span>
                      </div>
                    </div>
                    <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                      <span class="Last-year-value">{{ totalWeeks }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="dataCard">
                  <div class="row">
                    <div class="col-md-8 col-8 pr-0">
                      <div class="d-flex justify-content-between">
                        <div class="Last-year-title py-2">Start Date</div>
                        <span class="line"></span>
                      </div>
                    </div>
                    <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                      <span class="Last-year-value">{{ startDate }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="dataCard">
                  <div class="row">
                    <div class="col-md-8 col-8 pr-0">
                      <div class="d-flex justify-content-between">
                        <div class="Last-year-title py-2">Total Budget</div>
                        <span class="line"></span>
                      </div>
                    </div>
                    <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                      <span class="Last-year-value">{{ (Math.round(totalBudget * 100) / 100).toFixed(2) }} M</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <SummaryTable class="mt-5" :items="summaryData" :extraHeader="extraHeader" :currentPage="currentPage"
            :perPage="perPage" :totalRows="totalRows" ref="updateHead"
            @getSelectedPageEvent="getSelectedPageEvent($event)" />
        </details>
      </div>
      <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
        :time="10000" @clearToast="clearToast" />
      <div v-if="activeSaleGrowthTab === 'Budget Based Optimization'" class="row w-100 m-0"
        style="justify-content: flex-end">
        <w-button style="width: 256px" :buttonLoader="'normal'" :buttonText="showConstraintsText" class="my-5 mr-5"
          @buttonClicked="handleShowConstraints">
        </w-button>
        <!-- :isDisable="!showOptimizeButtons" -->
        <w-button style="width: 186px" :buttonLoader="'normal'" :isDisable="!showOptimizeButtons"
          :buttonText="'Optimize'" class="my-5" @buttonClicked="handlePredict">
        </w-button>
      </div>

      <div v-if="summarySelection" class="content-wrapper mt-5">
        <!---->
      </div>

      <div v-if="forecastButtons" class="row w-100 m-0" style="justify-content: flex-start">
        <w-button style="width: 256px" :buttonLoader="'normal'" :buttonText="showForecastingText"
          class="my-5 mr-5 d-none" @buttonClicked="handleShowSummaryTable">
        </w-button>
        <div class="d-flex" style="justify-content: flex-end">
          <w-button style="width: 186px" :buttonLoader="'normal'" :buttonText="'Forecast'"
            @buttonClicked="handleForecastClicked" class="my-5">
          </w-button>
        </div>
      </div>

      <w-table v-if="activeSaleGrowthTab === 'Budget Based Optimization'" :tableProperties="tableDataValues"
        :tableLoading="tableLoding" :showHeading="true" :headingText="'Optimization History'" :customRowColor="true"
        @retryModel="restartModel($event)" :noDataText="'No Model found'" @viewModelResult="viewModelResult($event)">
      </w-table>

      <div class="row w-100 m-0" style="justify-content: flex-end">
        <p class="mt-5"></p>
      </div>
    </div>
    <page-loader v-if="isLoading" />
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import SalesGrowthUL from "@/components/Solutions/SalesGrowthUL.vue";
import SummaryTable from "@/components/Table/SummaryTable.vue";
import DataTable from "@/widgets/DataTable.vue";
import Forecasting from "@/components/Modal/ForecastingModal.vue";
import Calendar from "@/widgets/Calendar.vue";
import { abbreviateNumber } from "js-abbreviation-number";

// import DatePicker from "@/widgets/DatePicker.vue";
import Toast from "@/components/Toast/Toast.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import PageLoader from "@/widgets/PageLoader.vue";

import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
const aimoDatapoemService = new AimoDatapoemServices();

export default {
  props: [],
  components: {
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    "w-calendar": Calendar,
    SalesGrowthUL,
    Toast,
    SummaryTable,
    Forecasting,
    // "w-datepicker": DatePicker,
    "w-white-dropdown": WhiteDropdown,
    "page-loader": PageLoader,
    "w-table": DataTable,
  },
  data() {
    return {
      showOptimizeButtons: false,
      min_threshold: 0,
      max_threshold: 0,
      isDisable: true,
      file: "No File chosen",
      uplodaData: "",
      seen: true,
      todaysDate: "",
      isViewDetails: false,
      showNextConstrint: true,
      transferBudget: 0,
      summarySelection: false,
      startDate: "",
      totalBudget: "",
      totalWeeks: "",
      tableLoding: true,
      summaryData: [],
      tableDataValues: {
        fields: [
          {
            key: "Started",
            label: "Started",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Brand",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Duration",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "KPI",
            label: "KPI",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },

          {
            key: "Total Budget",
            label: "Total Budget",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Progress",
            label: "Progress",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "actionRowUL",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      extraHeader: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      showSummaryDetails: true,
      summaryDropdown: {
        text: "Scenario 1",
        id: "fe71976e-d52c-452d-80c8-41f1ee2bda2f",
      },
      budgetOptmize: false,
      forecastButtons: true,
      showConstraints: false,
      showConstraintsText: "Show Constraints",
      showForecastingText: "show summary",
      constraint1: true,
      constraint2: false,
      selectedScenario: {},
      // selectedBrand: {
      //   text: localStorage.getItem("selectedBrandKPI"),
      //   id: localStorage.getItem("selectedBrandKPI").toLowerCase(),
      // },
      selectedBrand: {

      },
      selectedMedia: {

      },
      mediaDropdown: [
        // { text: "Overall", id: "Overall" },
        // { text: "Overall", id: "overall" },
      ],
      brandDropdown: [
        // { text: "Tresemme", id: "tresemme" },
        // { text: "Dove", id: "dove" },
      ],
      scenarioDropdown: [{ text: "Select", id: 1 }],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      isActive: true,
      constraintOne: true,
      constraintTwo: true,
      constraintThree: true,
      constraintFour: true,

      isPlusButtonActive: true,
      categorizedSelectedConnectors: [],
      // stepName: ["Platform", "DataSource", "KPI"],
      DurationDropdown: [
        { id: "1", text: "1 month" },
        { id: "2", text: "3 month" },
        { id: "3", text: "6 month" },
      ],
      saleGrowthtabs: [
        {
          category: "Forecasting",
        },
        {
          category: "Budget Based Optimization",
        },
      ],
      activeSaleGrowthTab: "Forecasting",
      sliderRevenueValue: 16,
      revenueData: [
        { name: "Last year Budget", value: 568 },
        { name: "Next Exp. Budget", value: 621 },
      ],
      sliderBudgetValue: 46,
      budgetData: [
        { name: "Last year Budget", value: 568 },
        { name: "Expected Budget", value: 621 },
      ],
      additionalDetails: [
        {
          name: "Constraint #1",
          product: "",
          dropdownList: [
            { text: "Digital", id: "0" },
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "2" },
            { text: "Print", id: "3" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          name: "Level #2",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          name: "Level #3",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
      ],
      levelsDetailsConstraintOne: [
        {
          id: 1,
          name: "Constraint #1",
          product: "",
          dropdownList: [
            { text: "Digital", id: "0" },
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "2" },
            { text: "Print", id: "3" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          id: 2,
          name: "Level #2",
          product: "",
          dropdownList: [
            // { text: "Digital", id: "0" },
            // { text: "Digital Performance", id: "1" },
            // { text: "TV", id: "2" },
            // { text: "Print", id: "3" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        // {
        //   id: 3,
        //   name: "Level #3",
        //   product: "",
        //   dropdownList: [
        //     { text: "Digital", id: "0" },
        //     { text: "Digital Performance", id: "1" },
        //     { text: "TV", id: "2" },
        //     { text: "Print", id: "3" },
        //   ],
        //   selectedValue: "",
        //   date: "",
        //   value: "",
        // },
      ],
      levelsDetailsConstraintTwo: [
        {
          id: 4,
          name: "Constraint #2",
          product: "",
          dropdownList: [
            { text: "Digital", id: "0" },
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "2" },
            { text: "Print", id: "3" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          id: 5,
          name: "Level #2",
          product: "",
          dropdownList: [
            { text: "Digital", id: "0" },
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "2" },
            { text: "Print", id: "3" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        // {
        //   id: 6,
        //   name: "Level #3",
        //   product: "",
        //   dropdownList: [
        //     { text: "Digital", id: "0" },
        //     { text: "Digital Performance", id: "1" },
        //     { text: "TV", id: "2" },
        //     { text: "Print", id: "3" },
        //   ],
        //   selectedValue: "",
        //   date: "",
        //   value: "",
        // },
      ],
      levelsDetailsConstraintThree: [
        {
          id: 7,
          name: "Constraint #3",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          id: 8,
          name: "Level #2",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        // {
        //   id: 9,
        //   name: "Level #3",
        //   product: "",
        //   dropdownList: [
        //     { text: "Digital Performance", id: "1" },
        //     { text: "TV", id: "1" },
        //     { text: "Print", id: "2" },
        //   ],
        //   selectedValue: "",
        //   date: "",
        //   value: "",
        // },

        //  {
        //   id: 10,
        //   name: "Level #4",
        //   product: "",
        //   dropdownList: [
        //     { text: "Digital Performance", id: "1" },
        //     { text: "TV", id: "1" },
        //     { text: "Print", id: "2" },
        //   ],
        //   selectedValue: "",
        //   date: "",
        //   value: "",
        // },
      ],
      levelsDetailsConstraintFour: [
        {
          id: 10,
          name: "Constraint #4",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        {
          id: 11,
          name: "Level #2",
          product: "",
          dropdownList: [
            { text: "Digital Performance", id: "1" },
            { text: "TV", id: "1" },
            { text: "Print", id: "2" },
          ],
          selectedValue: "",
          date: "",
          value: "",
        },
        // {
        //   id: 12,
        //   name: "Level #3",
        //   product: "",
        //   dropdownList: [
        //     { text: "Digital Performance", id: "1" },
        //     { text: "TV", id: "1" },
        //     { text: "Print", id: "2" },
        //   ],
        //   selectedValue: "",
        //   date: "",
        //   value: "",
        // },
      ],
      optimizationData: "",
      isLoading: false,
    };
  },

  methods: {

    getLevelDropdownDetails(event, index) {
      let brand = localStorage.getItem("selectedBrandKPI");
      if (index === 0) {
        aimoDatapoemService.getLevelDropdownDetails(brand).then((res) => {
          let data = res[0].levelTwo;
          let selectedValue = event.text;
          switch (selectedValue) {
            case "Digital":
              selectedValue = "digital";
              break;
            case "Digital Performance":
              selectedValue = "digital_performance";
              break;
            case "Print":
              selectedValue = "print";
              break;
            case "TV":
              selectedValue = "tv";
              break;
            case "None":
              selectedValue = "None";
              break;
            default:
              break;
          }
          let resultData = [];

          for (let i = 0; i < data.length; i++) {
            if (data[i].name === selectedValue) {
              resultData = data[i].value;
              break;
            }
          }
          // this.levelsDetailsConstraintOne[1].dropdownList = []
          let tempObject = [];
          for (let j = 0; j < resultData.length; j++) {
            let temp = { text: `${resultData[j].name}`, id: `${j}` };
            tempObject.push(temp);
          }
          tempObject.push({ id: "None", text: "None" });
          this.levelsDetailsConstraintOne[1].dropdownList = tempObject;
          this.levelsDetailsConstraintTwo[1].dropdownList = tempObject;
          this.levelsDetailsConstraintThree[1].dropdownList = tempObject;
          this.levelsDetailsConstraintFour[1].dropdownList = tempObject;
        });
      }
    },
    getSummaryTableInitialData() {
      this.isLoading = true;
      aimoDatapoemService
        .getAllSummaries(localStorage.getItem("selectedBrandKPI"), "Overall", sessionStorage.getItem("subId"))
        .then((res) => {
          this.scenarioDropdown = [];
          for (var i = 0; i < res.length; i++) {
            if (res[i].runId !== undefined) {
              this.scenarioDropdown.push({
                text: res[i].scenario_name,
                id: res[i].runId,
              });
              this.selectedScenario = {
                text: res.slice(-1)[0].scenario_name,
                id: res.slice(-1)[0].runId,
              };
            }
          }
          // this.selectedScenario = {
          //     text: res.slice(-1)[0].scenario_name,
          //     id: res.slice(-1)[0].runId,
          //   };
        })
        .then(() => {
          if (this.selectedScenario !== {}) {
            this.fetchScenarioData(this.selectedScenario);
          }
        });
    },
    fetchScenarioData(e) {
      aimoDatapoemService
        .getSummaryDetails(e.id, sessionStorage.getItem("subId"))
        .then((res) => {
          this.summaryData = res.document.data;

          //populate 3 fields above table
          this.startDate = res.document.summary.start_date;
          this.totalBudget = res.document.summary.total_budget;
          this.totalWeeks = res.document.summary.number_of_weeks;

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    // getPreviousMonthlyBudget() {
    //   aimoUnileverService
    //     .getPreviousBudget(
    //       localStorage.getItem("selectedBrandKPI"),
    //       this.selectedBudgetDuration.id
    //     )
    //     .then((res) => {
    //       this.max_threshold = res.overall_max_threshold;
    //       this.min_threshold = res.overall_min_threshold;
    //       // this.monthly_max_threshold = res.monthly_max_threshold;
    //       // this.monthly_min_threshold = res.monthly_min_threshold;
    //       this.previousBudget = this.formatValue(res.previous_months_budget);
    //     });
    // },

    checkForValidations(e) {
      this.showOptimizeButtons = e;

      // e === false ? this.toastData = {
      //     show: true,
      //     message: "Budget for One or More months for the selected scenario is not in the recommended range, please select a different scenario or upload new budget.",
      //     type: "",
      // } : ""
    },
    formatValue(data) {
      return abbreviateNumber(data, 2, {
        symbols: ["", "k", "M", "B", "T", "P", "E"],
      });
    },
    getScenarioName(e) {
      this.scenarioName = e.target.value;
      // if (this.scenarioDetails[index] != e) {
      //   this.scenarioDetails[index].scenarioName = e.target.value;
      // }
    },
    handleUploadTemplate() {
      this.showNextConstrint = true;
      if (this.uplodaData !== "" && this.scenarioName.length !== 0) {
        this.toastData = {
          show: true,
          message: "Our system is running",
          type: "success",
        };
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.uplodaData);
        aimoDatapoemService
          .uploadForcastingTemplate(
            formData,
            this.scenarioName,
            localStorage.getItem("selectedBrandKPI"),
            "Overall",
            this.numOfScenario,
            sessionStorage.getItem("subId"),
          )
          .then((res) => {
            // if(res.)

            this.forecastingData = res;
            this.isLoading = false;
            this.isViewDetails = true;
            this.showNextConstrint = false;

            this.toastData = {
              show: true,
              message: "Upload success",
              type: "success",
            };

            sessionStorage.setItem("viewDetails", true);
          })
          .catch((err) => {
            if (err.reason_phrase !== undefined) {
              this.toastData = {
                show: true,
                message: err.reason_phrase,
                type: "",
              };
            } else {
              this.toastData = {
                show: true,
                message: "Something went wrong, please try again",
                type: "",
              };
            }

            this.isLoading = false;
            this.isViewDetails = false;
            this.showNextConstrint = true;
            sessionStorage.setItem("viewDetails", false);
          });
      }
    },
    clearFileUploader(e) {
      this.$refs.fileInput.value = null;

      this.file = "No File chosen";
      this.seen = true;
      this.uplodaData = "";
      e.preventDefault();
    },
    onFileChange(event) {
      // this.scenarioDetails[index].fileChosen.seen = false;
      // this.scenarioDetails[index].fileChosen.fileData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.uploadData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.file = this.scenarioDetails[index].fileChosen.fileData

      this.seen = false;
      var fileData = event.target.files[0];
      this.uplodaData = event.target.files[0];
      this.file = fileData.name;
    },
    selectedWeeks(e) {
      this.numOfWeeks = e.target.value;
    },
    handleTemplateDownload() {
      let weeks = this.numOfWeeks;
      let startDate = this.formatDate(this.selectedForecastingDate);
      let brand = this.selectedBrand.text;
      let kpi = localStorage.selectedMediaKPI;
      let clientId = sessionStorage.getItem("subId");


      if (weeks.length !== 0 && startDate.length !== 0) {
        aimoDatapoemService
          .downloadForcastingTemplate(brand, kpi, weeks, startDate, clientId)
          .then(() => { });
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getTodaysDate() {
      let date = new Date();

      this.todaysDate = date;
    },
    selectForecastingDate(e) {
      this.selectedForecastingDate = e;
    },
    deleteModel() {
      this.$emit("deleteRow", this.selected);
    },
    updatePlan(val) {
      if (val === "delete") {
        this.$emit("updateToast", {
          show: true,
          type: "error",
          message: "Our system has deleted your plan successfully!",
        });
      } else if (val === "share") {
        this.$emit("updateToast", {
          show: true,
          type: "success",
          message: "Yay, your plan has been shared successfully!",
        });
      }
    },
    doneProgress() {
      this.$router.push("/solutions/in/platform/stat");
    },
    handleForecastClicked() {
      this.$refs.planModal.open({});
      this.$refs.planModal.getSummaries();
      this.$refs.planModal.setScenario(this.selectedScenario);
    },
    handleAdditionSceario() {
      this.showNextConstrint = true;
      if (this.scenarioName.length !== 0) {
        this.numOfScenario += 1;
        (this.scenarioName = ""), (this.file = "No File chosen");
        this.seen = true;
        this.uplodaData = "";
      }
    },
    restartModel() {
      //   if (e.preProcess.status === "error") {
      //       console.log("res");
      //     });
      //   } else if (e.attribution.status === "error") {
      //       console.log("res");
      //     });
      //   } else if (e.optimization.status === "error") {
      //       console.log("res");
      //     });
      // }
    },
    viewModelResult(e) {
      const budget = e["Total Budget"] / 1000000;
      const months = e.Duration;
      let clientId = sessionStorage.getItem("subId")

      aimoDatapoemService.viewResultFromHistory(e._id, clientId).then((res) => {
        localStorage.setItem("optimizeData", JSON.stringify(res));
        sessionStorage.setItem("currentTab", "bbo");
        sessionStorage.setItem("fromHistoryTable", true);
        sessionStorage.setItem("fronScenarioPlanning", false);

        sessionStorage.setItem(
          "obbData",
          JSON.stringify({
            month: months,
            budget: [budget],
          })
        );
        this.$router.push("/solutions/ul/scenario-planning/planning-results");
        // localStorage.setItem("optimizeData", res)
      });
    },
    getOptimizationHistoryTable() {
      this.tableLoding = true;
      var clientId = sessionStorage.getItem("subId");
      aimoDatapoemService.getSummaryTableData(clientId).then((res) => {
        // sessionStorage.setItem("currentTab", "bbo");
        this.tableLoding = false;
        this.tableDataValues.items = [];
        this.tableDataValues.items = res;
      });
    },
    summaryTableData(e) {
      //populate dropdown
      aimoDatapoemService
        .getAllSummaries(localStorage.getItem("selectedBrandKPI"), "Overall", sessionStorage.getItem("subId"))
        .then((res) => {
          this.scenarioDropdown = [];
          for (var i = 0; i < res.length; i++) {
            if (res[i].runId !== undefined) {
              this.scenarioDropdown.push({
                text: res[i].scenario_name,
                id: res[i].runId,
              });
            }
          }
          this.selectedScenario = {
            text: res.slice(-1)[0].scenario_name,
            id: res.slice(-1)[0].runId,
          };
        });
      //populate table
      this.summaryData = [];
      this.summaryData = e.data;

      //populate fields above summary table
      this.startDate = e.summary.start_date;
      this.totalBudget = e.summary.total_budget;
      this.totalWeeks = e.summary.number_of_weeks;
    },
    selectScenario(e) {
      this.selectedScenario = e;
      aimoDatapoemService.getSummaryDetails(e.id, sessionStorage.getItem("subId")).then((res) => {
        //populate table
        this.summaryData = res.document.data;

        //populate 3 fields above table
        this.startDate = res.document.summary.start_date;
        this.totalBudget = res.document.summary.total_budget;
        this.totalWeeks = res.document.summary.number_of_weeks;
      });
    },
    getSelectedPageEvent(e) {
      if (e !== this.currentPage) {
        this.currentPage = e;
        this.getTableData();
      }
    },
    showViewDetails() {
      if (this.activeSaleGrowthTab === "Forecasting") {
        // document.getElementsByClassName("expand-details").open = true;
        this.showSummaryDetails = true;
      } else {
        this.showSummaryDetails = false;
      }
    },
    handleShowConstraints() {
      this.showConstraints = !this.showConstraints;
      if (this.showConstraints === false) {
        this.showConstraintsText = "Show Constraints";
      } else {
        this.showConstraintsText = "Hide Constraints";
      }
    },
    handleShowSummaryTable() {
      this.showSummaryDetails = !this.showSummaryDetails;
      if (this.showForecastingText === false) {
        this.showForecastingText = "Show summary";
      } else {
        this.showForecastingText = "Hide summary";
      }
    },

    getBudget(e) {
      return e;
    },
    getTotalBudget(e) {
      this.transferBudget = e.budget[0];
      // console.log("getTotalBudget", e);
      this.optimizationData = e;

      sessionStorage.setItem("obbData", JSON.stringify(e));
      this.isDisable = e.optimizeButtonStatus;

      return e;
    },
    saveToLocal() {
      // if (this.selectedMedia.id && this.selectedBrand.id !== null) {
      localStorage.setItem(
        "selectedKPISource",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      console.log(this.selectedBrand.text)
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);
      localStorage.setItem("selectedMediaKPI", this.selectedMedia.text);

      // localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      // }
    },
    selectBrand(e) {
      this.selectedBrand = e;
      this.saveToLocal();
      if (e.text === "Tresemme") {
        this.levelsDetailsConstraintOne[0].dropdownList = [];
        this.levelsDetailsConstraintTwo[0].dropdownList = [];
        this.levelsDetailsConstraintThree[0].dropdownList = [];
        this.levelsDetailsConstraintFour[0].dropdownList = [];
        this.levelsDetailsConstraintOne[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "print", text: "print" },
          { id: "None", text: "None" }
        );
        this.levelsDetailsConstraintTwo[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "print", text: "print" },
          { id: "None", text: "None" }
        );
        this.levelsDetailsConstraintThree[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "None", text: "None" },
          { id: "print", text: "print" }
        );
        this.levelsDetailsConstraintFour[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "print", text: "print" },
          { id: "None", text: "None" }
        );
      }
      if (e.text === "Dove") {
        this.levelsDetailsConstraintOne[0].dropdownList = [];
        this.levelsDetailsConstraintTwo[0].dropdownList = [];
        this.levelsDetailsConstraintThree[0].dropdownList = [];
        this.levelsDetailsConstraintFour[0].dropdownList = [];
        this.levelsDetailsConstraintOne[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "None", text: "None" }
        );
        this.levelsDetailsConstraintTwo[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "None", text: "None" }
        );
        this.levelsDetailsConstraintThree[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "None", text: "None" }
        );
        this.levelsDetailsConstraintFour[0].dropdownList.push(
          { id: "digital", text: "digital" },
          { id: "digital_performance", text: "digital_performance" },
          { id: "tv", text: "tv" },
          { id: "None", text: "None" }
        );
      }
    },
    selectMedia(e) {
      this.selectedMedia = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      this.saveToLocal();
      // this.callApis();
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    // checkOptimizationStatus() {
    //   if (this.optimizationData !== "") {
    //     this.isActive = true;
    //   }
    // },
    handleConstraints() {
      if (this.constraintOne === false) {
        this.constraintTwo = true;
      } else if (this.constraintTwo === false) {
        this.constraintTwo = true;
      } else if (this.constraintThree === false) {
        this.constraintThree = true;
      } else if (this.constraintFour === false) {
        this.constraintFour = true;
      }
      // if (this.constraintTwoWithLevels === false) {
      //   this.constraintTwoWithLevels = true;
      // } else if (this.constraintThreeWithLevels === false) {
      //   this.constraintThreeWithLevels = true;
      // } else if (this.constraintFourWithLevels === false) {
      //   this.constraintFourWithLevels = true;
      // }
    },
    selectedKPiOne(e, index) {
      if (this.levelsDetailsConstraintOne[index].selectedValue != e) {
        this.levelsDetailsConstraintOne[index].selectedValue = e.text;
      }
    },
    selectedKPiTwo(e, index) {
      if (this.levelsDetailsConstraintTwo[index].selectedValue != e) {
        this.levelsDetailsConstraintTwo[index].selectedValue = e.text;
      }
    },
    selectedKPiThree(e, index) {
      if (this.levelsDetailsConstraintThree[index].selectedValue != e) {
        this.levelsDetailsConstraintThree[index].selectedValue = e.text;
      }
    },
    selectedKPiFour(e, index) {
      if (this.levelsDetailsConstraintFour[index].selectedValue != e) {
        this.levelsDetailsConstraintFour[index].selectedValue = e.text;
      }
    },
    changeSaleGrowthTab(tabName) {
      this.activeSaleGrowthTab = tabName;
      if (tabName === "Budget Based Optimization") {
        this.budgetOptmize = true;
        this.forecastButtons = false;
        this.showSummaryDetails = false;
      } else if (tabName === "Forecasting") {
        this.budgetOptmize = false;
        this.forecastButtons = true;
        this.showSummaryDetails = true;
      }
    },

    getOptimizedBudget(constraints) {
      this.toastData = {
        show: true,
        message: "Our system is running",
        type: "success",
      };

      // console.log(this.optimizationData)

      this.isLoading = true;

      aimoDatapoemService
        .getOptimizationData({
          download: false,
          clientId: sessionStorage.getItem("subId"),
          duration: +this.optimizationData.months,
          budget: this.optimizationData.budget,
          brand: this.selectedBrand.text,
          kpi: localStorage.getItem("selectedMediaKPI"),
          constraints: [{ ConstList: constraints }],
        })
        .then((res) => {
          let sum = this.optimizationData.budget.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
          localStorage.setItem("optimizeData", JSON.stringify(res));
          sessionStorage.setItem("currentTab", "bbo");
          sessionStorage.setItem("fromHistoryTable", false);
          sessionStorage.setItem("fronScenarioPlanning", false);

          sessionStorage.setItem(
            "obbData",
            JSON.stringify({
              month: this.optimizationData.months,
              budget: [sum],
            })
          );
          this.$router.push({
            name: "PlanningResults UL",
            params: { optimizedBudgetData: res, budget: this.transferBudget },
          });
        })
        .catch((err) => {
          this.isLoading = false;

          this.toastData = {
            show: true,
            message: err,
            type: "",
          };
        });
    },

    handlePredict() {
      let temp = [];
      let data = this.levelsDetailsConstraintOne;
      let data1 = this.levelsDetailsConstraintTwo;
      let data2 = this.levelsDetailsConstraintThree;
      let data3 = this.levelsDetailsConstraintFour;

      if (data[0].selectedValue !== "" && data[1].selectedValue === undefined) {
        temp.push({
          name: data[0].selectedValue,
          value: +data[1].value * 1000,
          direction: 0,
        });
      } else if (data[0].selectedValue !== "" && data[1].selectedValue !== "") {
        temp.push({
          name: data[1].selectedValue,
          value: +data[1].value * 1000,
          direction: 0,
        });
      } else if (
        data[0].selectedValue === undefined &&
        data[1].selectedValue === undefined
      ) {
        console.log("");
      }

      if (data1[0].selectedValue !== "" && data1[1].selectedValue === undefined) {
        temp.push({
          name: data1[0].selectedValue,
          value: +data1[1].value * 1000,
          direction: 0,
        });
      } else if (data1[0].selectedValue !== "" && data1[1].selectedValue !== "") {
        temp.push({
          name: data1[1].selectedValue,
          value: +data1[1].value * 1000,
          direction: 0,
        });
      } else if (
        data1[0].selectedValue === undefined &&
        data1[1].selectedValue === undefined
      ) {
        console.log("");
      }

      if (data2[0].selectedValue !== "" && data2[1].selectedValue === undefined) {
        temp.push({
          name: data2[0].selectedValue,
          value: +data2[1].value * 1000,
          direction: 0,
        });
      } else if (data2[0].selectedValue !== "" && data2[1].selectedValue !== "") {
        temp.push({
          name: data2[1].selectedValue,
          value: +data2[1].value * 1000,
          direction: 0,
        });
      } else if (
        data2[0].selectedValue === undefined &&
        data2[0].selectedValue === undefined
      ) {
        console.log("");
      }

      if (data3[0].selectedValue !== "" && data3[1].selectedValue === undefined) {
        temp.push({
          name: data3[0].selectedValue,
          value: +data3[1].value * 1000,
          direction: 0,
        });
      } else if (data3[0].selectedValue !== "" && data3[1].selectedValue !== "") {
        temp.push({
          name: data3[1].selectedValue,
          value: +data3[1].value * 1000,
          direction: 0,
        });
      } else if (
        data3[0].selectedValue === undefined &&
        data3[1].selectedValue === undefined
      ) {
        console.log("");
      }
      let filteredData = temp
        .filter((x) => x.name !== undefined)
        .filter((y) => y.name !== "None");
      let result = filteredData.filter(
        (value, index, self) => index === self.findIndex((t) => t.name === value.name)
      );
      if (result.length !== filteredData.length) {
        this.toastData = {
          show: true,
          type: "",
          message: "Constraints are repeating, please change and try again",
        };
      } else {
        this.getOptimizedBudget(filteredData);
      }
      // console.log(result);
    },
    getBrands(cliendId) {
      this.brandDropdown = []
      this.mediaDropdown = []
      aimoDatapoemService.getClientBrandDetails(cliendId).then((res) => {
        let dropdown_labels = Object.keys(res[0])
        this.dropdownLabel = dropdown_labels[2]

        let brand = res[0].Brand
        let kpi = res[0][this.dropdownLabel]

        for (var brands = 0; brands < brand.length; brands++) {
          this.brandDropdown.push({ id: brand[brands], text: brand[brands] })
        }

        for (var kpis = 0; kpis < kpi.length; kpis++) {
          this.mediaDropdown.push({ id: kpi[kpis], text: kpi[kpis] })
        }

        this.selectedBrand = this.brandDropdown[0]
        this.selectedMedia = this.mediaDropdown[0]

        this.saveToLocal()
      }).catch((err) => {
        alert(err)
      })
    },
  },
  created() {
    this.getBrands(sessionStorage.getItem("subId"))

    // this.$nextTick(function () {
    //   window.setInterval(() => {
    this.getOptimizationHistoryTable();
    //   }, 10000);
    // });
    this.getSummaryTableInitialData();
    // this.summaryTableData();
  },
};
</script>
<style scoped>
.content-wrapper {
  margin-top: 24px;
  padding: 28px 23px 27px 24px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.date-wraper {
  height: 60px;
  background-color: #eff5ff;
}

.date-label-wrapper {
  width: 40%;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.line {
  width: 2px;
  height: 29px;
  background: #c6ccd6;
}

.date-input {
  height: 60px;
  width: 100%;
  font-size: 16px;
  padding: 0 20px;
}

.input-wrapper {
  height: 60px;
  font-size: 10px;
  padding-left: 21px;
}

input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}

input:focus {
  background-color: #eff5ff;
  outline: none;
}

input[type="number"] {
  outline: none;
  box-shadow: none;
  font-family: ProximaNovaBold;
  font-size: 16px;
}

.changeColor .disableButton {
  color: #fff !important;
}

.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}

.action {
  color: #000;
}

.container {
  height: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card {
  display: block;
}

.future {
  width: 144px;
  height: 24px;
  margin: 0 48px 7px 180px;
  /* margin-left: 500px; */
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.dataCard {
  max-width: 300px;
  width: 100%;
  min-height: 55px;
  margin-bottom: 100px;
  padding: 13px 9px 13px 15px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.dataCard a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.middleDatacard {
  margin-bottom: 31px;
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
  height: 240px;
}

.Last-year-title {
  font-family: ProximaNovaRegular;
  color: #727988;
  font-size: 16px;
}

.line {
  width: 1px;
  margin: 0 11px;
  border: solid 1px #c6ccd6;
}

.Last-year-value {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  color: #050505;
}

@media screen and (max-width: 768px) {
  .chart-row {
    margin-top: 30px;
  }

  .dataCard {
    margin-bottom: 30px;
  }
}

.Path-8 {
  /* width: 40px;
  height: 1px; */
  margin: 0px 0 0 230px;
  /* object-fit: contain;
  border: solid 2px #050505; */
}

/* .Path-8new {
  margin: 0px 0 0 550px;
} */

/* .roi {
  width: 117px;
  height: 24px;
  margin: 0 0 7px -100px;
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
} */

.sales-growth {
  width: 156px;
  height: 24px;
  margin: 0 303px 19px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
}

input::-webkit-inner-spin-button {
  display: none;
}

.Additional-details {
  width: 259px;
  height: 24px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  outline: none;
}

.sliderIcon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: #f3f3f3;
}

.sliderIcon i {
  font-size: 20px;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}

.Additional-details::marker {
  display: flex;
  float: right;
  line-height: center;
  justify-content: center;
}
</style>
