<template>
  <div class="w-100 d-flex">
    <div class="dropdownLabel content-center" style="width: 40%" v-if="labelText !== ''">
      <div class="font-medium pl-4 w-100">{{ labelText }}</div>
      <div class="line"></div>
    </div>
    <div
      class="custom-select"
      :tabindex="tabindex"
      @blur="open = false"
      :style="labelText !== '' ? 'width: 60%' : 'width:100%'"
    >
      <date-picker
        :class="{ 'under-line': underLine }"
        v-model="time2"
        format="MMM YYYY"
        type="month"
        @input="getDate($event)"
      ></date-picker>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "../assets/style/datepicker/index.css";

export default {
  name: "w-calendar",
  props: {
    // options: {
    //   type: Array,
    //   required: true,
    // },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectedDate: {
      type: Date,
      required: false,
      default: new Date(),
    },
    placeHolder: {
      type: String,
      required: false,
      default: "Select",
    },
    labelText: {
      type: String,
      required: false,
      default: "",
    },
    underLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { DatePicker },
  data() {
    return {
      selected: this.placeHolder.length > 0 ? this.placeHolder : "Select",
      open: false,
      selectedObj: {},
      time2: null,
    };
  },
  methods: {
    getDate(e) {
      this.$emit("getDate", e);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dropdownLabel {
  background: #eff5ff;
  color: #313846;
  height: 55px;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
.default {
  color: #9aafd4 !important;
  font-family: ProximaNovaRegular !important;
}
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  height: 60px;
  line-height: 55px;
  border-radius: 0px;
  border: 0px;
  appearance: auto;
  padding: 0px;
}
.custom-select:focus {
  box-shadow: none;
}
.disable-option {
  color: #313846;
  font-family: ProximaNovaBold !important;
  background-color: #eff5ff;
}
.selected {
  background-color: #eff5ff;
  border: 0px solid #858586;
  color: #222a37;
  font-family: ProximaNovaBold;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
}

.selected.open {
  border: 0px solid;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 15px;
  right: 0px;
  width: 35px;
  height: 36px;
  background: url("../assets/Icons/calendar.svg") no-repeat;
}

.items {
  color: #313846;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  right: 0;
  font-size: medium;
  z-index: 99;
}
.items::-webkit-scrollbar {
  background-color: #eff5ff;
  width: 10px;
  height: 7px;
}
.items::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.items::-webkit-scrollbar-thumb {
  background: #313846;
  border: 0px none #ffffff;
  border-radius: 10px;
}
.item {
  color: #313846;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
  font-family: ProximaNovaRegular;
}
.item.disabled {
  pointer-events: none;
  background-color: lightgray;
  font-weight: bold;
}
.item:hover {
  background-color: #eff5ff;
  font-family: ProximaNovaBold;
}

.selectHide {
  display: none;
}

.under-line {
  border-bottom: 1px solid black;
}
</style>
