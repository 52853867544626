<template>
  <div class="row chart-row" :class="[bigRangeSize ? 'chart-row-short' : '']">
    <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <div v-if="activeTab === 'Budget Based Optimization'">
      <div class="d-flex justify-content-end">
        <div class="w-100 mb-5 d-flex justify-content-start" style="float: right">
          <input type="radio" class="form-check-input ml-5" id="exampleCheck1" name="name" value="One"
            v-model="budgetFromScenario" />
          <label class="form-check-label Remember-me" for="exampleCheck1">Select from existing scenario</label>
        </div>
        <div style="width: 900px">
          <div class="w-100 mb-5 d-flex justify-content-end d-none" style="float: right">
            <input type="radio" value="Two" class="form-check-input ml-5" id="exampleCheck2" name="name"
              v-model="budgetFromScenario" />
            <label class="form-check-label Remember-me" for="exampleCheck2">Enter the new budget</label>
          </div>
        </div>
      </div>

      <!--For Auto upload-->
      <div v-if="budgetFromScenario === 'One'">
        <div>
          <b-row>
            <b-col md="3" v-if="!bigRangeSize" class="d-flex align-items-start justify-content-center" order="2"
              order-md="1">
              <w-white-dropdown :options="scenarioDropdownDetails" :placeHolder="'Select'"
                :selectedOption="selectedScenarioDropdown" :labelText="'Scenario'" class="mr-5"
                style="height: 60px; width: 500px" @input="selectScenario($event)" />
            </b-col>

            <!-- slider -->
            <b-col :lg="bigRangeSize ? 12 : 6" class="col-md-6 d-flex justify-content-center" order="1" order-md="2">
              <div>
                <div class="slider-wrapper">
                  <!--Two dropdowns here-->
                  <div class="d-none" style="font-size: 16px; margin-right: 50px">
                    <w-white-dropdown :options="scenarioDates" :placeHolder="'Select'"
                      :selectedOption="selectedStartDate" :labelText="'Start Date'" class="mr-5 d-none"
                      style="height: 60px" @input="selectStartDate($event)" />
                  </div>

                  <div style="width: 300px">
                    <div class="dataCard">
                      <div class="row">
                        <div class="col-md-8 col-8 pr-0">
                          <div class="d-flex justify-content-between">
                            <div class="Last-year-title py-2">Months</div>
                            <span class="line"></span>
                          </div>
                        </div>
                        <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                          <div v-if="!loader">
                            <div class="Budgetloader"></div>
                          </div>
                          <span class="Last-year-value" v-else>{{
                              selectScenarioDuration.text
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <w-white-dropdown
                    :options="scenarioDurations"
                    :placeHolder="'Select'"
                    :selectedOption="selectScenarioDuration"
                    :labelText="'Duration'"
                    class="mr-5"
                    style="height: 60px"
                    @input="selectBudgetDuration($event)"
                  /> -->

                  <!-- <round-slider
            class="d-none"
            :style="{
              '--handleColor': handleColor === 'default' ? '#f3f3f3' : handleColor,
            }"
            v-model="sliderValue1"
            start-angle="0"
            end-angle="+180"
            line-cap="round"
            :radius="sliderRadius"
            :width="sliderWidth"
            pathColor="#c9ced5"
            rangeColor="#536dfe"
            handleSize="+15"
            :step="step"
            :editableTooltip="false"
            :change="sliderChange"
            :tooltipFormat="
              (val) => {
                return `<div style='margin-top:-75px'>
                            <div style='font-family: ProximaNovaRegular;font-size: 20px;color: #9aafd4'>${sliderName}</div>
                            <div style='font-family: ProximaNovaBOld;font-size: 24px;color: #222a37'>${val.value}% </div>
                        </div>`;
              }
            "
          /> -->
                  <!-- <input
                    class="input-box m-0"
                    style="width: 30%; font-size: 16px"
                    placeholder="Enter total budget in millions"
                    v-model="totalBudgetSum"
                    @input="totalBudget($event)"
                  /> -->
                </div>

                <!-- <div
                  v-for="(displayMonths, index) in scnearioNameDisplay"
                  :key="index"
                  class="input-container"
                >

                <w
              
                  <h3>{{ displayMonths.name }}</h3>
                  <h3>{{ displayMonths.budget }}</h3>
                </div> -->

                <div v-if="bigRangeSize" class="d-flex justify-content-center mt-5">
                  <div style="width: 100px" class="dataCard" :class="[bigRangeSize ? 'middleDatacard' : '']">
                    <div class="row">
                      <div class="col-8 pr-0">
                        <div class="d-flex justify-content-around">
                          <div class="Last-year-title py-2">{{ data[0].name }}</div>
                          <span class="line"></span>
                        </div>
                      </div>
                      <div class="col-4 pl-0 d-flex align-items-center">
                        <div v-if="!loader">
                          <div class="Budgetloader"></div>
                        </div>
                        <span class="Last-year-value" v-else>{{ data[0].value }} {{ specialChar }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col md="3" v-if="!bigRangeSize" class="d-flex align-items-start justify-content-center" order="3"
              order-md="3">
              <div class="dataCard" style="width: 250px">
                <div class="row">
                  <div class="col-md-8 col-8 pr-0">
                    <div class="d-flex justify-content-between">
                      <div class="Last-year-title py-2">Total Budget</div>
                      <span class="line"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                    <div v-if="!loader">
                      <div class="Budgetloader"></div>
                    </div>
                    <span class="Last-year-value" v-else>{{ scenarioTotalBudget }} M</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <div style="margin-top: -100px" class="d-flex flex-row justify-content-center">
              <!--all months input here-->
              <div class="input-container" v-for="(ScenarioDisplay, index) in scnearioNameDisplay" :key="index">
                <div>
                  <h4 class="ProductNew d-flex justify-content-center m-5">
                    {{ ScenarioDisplay.name }}
                  </h4>
                  <div class="input-box d-flex justify-content-center align-items-center" style="font-size: 15px">
                    {{ (Math.round(ScenarioDisplay.budget * 100) / 100).toFixed(2) }} M
                  </div>
                </div>
              </div>
            </div>

            <b-container class="bv-example-row mt-5">
              <b-row class="mt-5">
                <b-col>
                  <h3 class="text-small ml-3" style="font-family: Sora; font-size: 15px">
                    Min monthly Budget:
                    {{ this.formatValue(this.monthly_min_threshold) }},
                  </h3>
                </b-col>
                <b-col>
                  <h3 class="text-small ml-3" style="font-family: Sora; font-size: 15px">
                    Max monthly Budget: {{ this.formatValue(this.monthly_max_threshold) }}
                  </h3>
                </b-col>
                <b-col>
                  <!-- <h3 v-if="showWarning" style="color: red">
                    Budget for One or More months for the selected scenario is not in the
                    recommended range, please select a different scenario or upload new
                    budget.
                  </h3> -->
                </b-col>
              </b-row>
            </b-container>
          </b-row>
        </div>
      </div>

      <!--For Manual Upload-->
      <div v-if="budgetFromScenario === 'Two'">
        <b-row>
          <b-col>
            <input v-model="bboScenarioName" @input="getbboScenarioName($event)" placeholder="Enter scenario name"
              class="input-box" style="width: 70%; font-size: 15px" />
          </b-col>
          <b-col>
            <input id="upload-btn" ref="uploadInput" accept=".csv" hidden @change="onFileUpload" type="file"
              class="input-box" />
            <label class="file-label" for="upload-btn" v-if="bboseen">Choose File</label>
            <span id="file-chosen" ref="file-chosen1" class="d-flex" style="justify-content: space-between">{{ bboFile
            }}
              <label class="remove-label mr-5" v-if="!bboseen" @click="clearUploader">Remove</label>
            </span>
          </b-col>
          <b-col>
            <w-button @buttonClicked="handleBBOptimization" :buttonText="'Upload'" style="width: 70%" />
          </b-col>
        </b-row>

        <!-- v-if="showSelectFromExistingScenario" -->
        <div v-if="showSelectFromExistingScenario" style="margin-top: 120px">
          <div class="card-header font-large ProximaNovaBold ml-3"
            style="background: transparent; border: 0px !important">
            Select Scenario to Run Optimization
          </div>
          <b-row style="margin-top: 30px">
            <div>
              <div>
                <b-row>
                  <b-col md="3" v-if="!bigRangeSize" class="d-flex align-items-start justify-content-center" order="2"
                    order-md="1">
                    <w-white-dropdown :options="scenarioDropdownDetails" :placeHolder="'Select'"
                      :selectedOption="selectedScenarioDropdown" :labelText="'Scenario'" class="ml-5"
                      style="height: 60px; width: 500px" @input="selectScenario($event)" />
                  </b-col>

                  <!-- slider -->
                  <b-col :lg="bigRangeSize ? 12 : 6" class="col-md-6 d-flex justify-content-center" order="1"
                    order-md="2">
                    <div>
                      <div class="slider-wrapper">
                        <!--Two dropdowns here-->
                        <div class="d-none" style="font-size: 16px; margin-right: 50px">
                          <w-white-dropdown :options="scenarioDates" :placeHolder="'Select'"
                            :selectedOption="selectedStartDate" :labelText="'Start Date'" class="mr-5 d-none"
                            style="height: 60px" @input="selectStartDate($event)" />
                        </div>

                        <div style="width: 300px">
                          <div class="dataCard">
                            <div class="row">
                              <div class="col-md-8 col-8 pr-0">
                                <div class="d-flex justify-content-between">
                                  <div class="Last-year-title py-2">Months</div>
                                  <span class="line"></span>
                                </div>
                              </div>
                              <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                                <div v-if="!loader">
                                  <div class="Budgetloader"></div>
                                </div>
                                <span class="Last-year-value" v-else>{{
                                    selectScenarioDuration.text
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <w-white-dropdown
                    :options="scenarioDurations"
                    :placeHolder="'Select'"
                    :selectedOption="selectScenarioDuration"
                    :labelText="'Duration'"
                    class="mr-5"
                    style="height: 60px"
                    @input="selectBudgetDuration($event)"
                  /> -->

                        <!-- <round-slider
            class="d-none"
            :style="{
              '--handleColor': handleColor === 'default' ? '#f3f3f3' : handleColor,
            }"
            v-model="sliderValue1"
            start-angle="0"
            end-angle="+180"
            line-cap="round"
            :radius="sliderRadius"
            :width="sliderWidth"
            pathColor="#c9ced5"
            rangeColor="#536dfe"
            handleSize="+15"
            :step="step"
            :editableTooltip="false"
            :change="sliderChange"
            :tooltipFormat="
              (val) => {
                return `<div style='margin-top:-75px'>
                            <div style='font-family: ProximaNovaRegular;font-size: 20px;color: #9aafd4'>${sliderName}</div>
                            <div style='font-family: ProximaNovaBOld;font-size: 24px;color: #222a37'>${val.value}% </div>
                        </div>`;
              }
            "
          /> -->
                        <!-- <input
                    class="input-box m-0"
                    style="width: 30%; font-size: 16px"
                    placeholder="Enter total budget in millions"
                    v-model="totalBudgetSum"
                    @input="totalBudget($event)"
                  /> -->
                      </div>

                      <!-- <div
                  v-for="(displayMonths, index) in scnearioNameDisplay"
                  :key="index"
                  class="input-container"
                >

                <w
              
                  <h3>{{ displayMonths.name }}</h3>
                  <h3>{{ displayMonths.budget }}</h3>
                </div> -->

                      <div v-if="bigRangeSize" class="d-flex justify-content-center mt-5">
                        <div style="width: 100px" class="dataCard" :class="[bigRangeSize ? 'middleDatacard' : '']">
                          <div class="row">
                            <div class="col-8 pr-0">
                              <div class="d-flex justify-content-around">
                                <div class="Last-year-title py-2">{{ data[0].name }}</div>
                                <span class="line"></span>
                              </div>
                            </div>
                            <div class="col-4 pl-0 d-flex align-items-center">
                              <div v-if="!loader">
                                <div class="Budgetloader"></div>
                              </div>
                              <span class="Last-year-value" v-else>{{ data[0].value }} {{ specialChar }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col md="3" v-if="!bigRangeSize" class="d-flex align-items-start justify-content-center" order="3"
                    order-md="3">
                    <div class="dataCard" style="width: 250px">
                      <div class="row">
                        <div class="col-md-8 col-8 pr-0">
                          <div class="d-flex justify-content-between">
                            <div class="Last-year-title py-2">Total Budget</div>
                            <span class="line"></span>
                          </div>
                        </div>
                        <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                          <div v-if="!loader">
                            <div class="Budgetloader"></div>
                          </div>
                          <span class="Last-year-value" v-else>{{ scenarioTotalBudget }} M</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <div style="margin-top: -100px" class="d-flex flex-row justify-content-center">
                    <!--all months input here-->
                    <div class="input-container" v-for="(ScenarioDisplay, index) in scnearioNameDisplay" :key="index">
                      <div>
                        <h4 class="ProductNew d-flex justify-content-center m-5">
                          {{ ScenarioDisplay.name }}
                        </h4>
                        <div class="input-box d-flex justify-content-center align-items-center" style="font-size: 15px">
                          {{
                              (Math.round(ScenarioDisplay.budget * 100) / 100).toFixed(2)
                          }}
                          M
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-container class="bv-example-row mt-5">
                    <b-row class="mt-5">
                      <b-col>
                        <h3 class="text-small ml-3" style="font-family: Sora; font-size: 15px">
                          Min monthly Budget:
                          {{ this.formatValue(this.monthly_min_threshold) }},
                        </h3>
                      </b-col>
                      <b-col>
                        <h3 class="text-small ml-3" style="font-family: Sora; font-size: 15px">
                          Max monthly Budget:
                          {{ this.formatValue(this.monthly_max_threshold) }}
                        </h3>
                      </b-col>
                      <b-col>
                        <!-- <h3 v-if="showWarning" style="color: red">
                    Budget for One or More months for the selected scenario is not in the
                    recommended range, please select a different scenario or upload new
                    budget.
                  </h3> -->
                      </b-col>
                    </b-row>
                  </b-container>
                </b-row>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </div>

    <div style="margin-left: 30px; margin-right: 30px" v-if="activeTab === 'Forecasting'">
      <h2 class="ProximaNovaBold d-flex justify-content-center">
        Step 1: Download the Format
      </h2>
      <div style="margin-top: 20px">
        <b-row>
          <b-col>
            <div class="card-header font-large ProximaNovaBold content-center" style="
                justify-content: space-between;
                background: transparent;
                border: 0px !important;
                margin-top: 50px;
              ">
              <div>Enter the number of weeks</div>
            </div>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col>
            <input v-model="numOfWeeks" class="input-box m-0" style="width: 300px; font-size: 16px"
              placeholder="Enter number of weeks" @input="selectedWeeks($event)" />
          </b-col>
          <b-col>
            <h3 style="margin-top: -25px" class="font-large ProximaNovaBold">
              Enter the start date
            </h3>

            <w-calendar :placeHolder="'Select'" :labelText="'Date'" class="mb-5"
              @getDate="selectForecastingDate($event)" />
          </b-col>
          <b-col>
            <w-button style="width: 356px; margin-right: 50px; font-size: 20px" :buttonLoader="'normal'"
              :buttonText="'Download'" @buttonClicked="handleTemplateDownload">
            </w-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col style="margin-top: 80px">
            <h2 style="font-size: 20px" class="ProximaNovaBold d-flex justify-content-center">
              Step 2: Upload the Data
            </h2>
            <div class="card-header font-large ProximaNovaBold content-center" style="
                justify-content: space-between;
                background: transparent;
                border: 0px !important;
                margin-top: 50px;
              ">
              <div class="mt-4">Enter name for the scenario</div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="d-none" v-for="(scenario, index) in scenarioDetails" :key="index">
        <b-row>
          <b-col>
            <input v-model="scenarioName" class="input-box" style="width: 256px; font-size: 16px"
              placeholder="Enter scenario name" @input="getScenarioName($event, index)" />
          </b-col>
          <b-col>
            <div style="width: 330px" class="px-0 pb-5 d-flex justify-center input-types">
              <input type="file" id="actual-btn" ref="fileInput" accept=".csv" @change="onFileChange($event, index)"
                hidden />
              <label class="file-label" for="actual-btn" v-if="seen">Choose File</label>
              <span id="file-chosen" ref="file-chosen" class="d-flex"
                style="justify-content: space-between; font-size: 15px">
                {{ file }}
                <label v-if="!seen" class="remove-label mr-5" @click="clearFileUploader($event, index)">Remove</label>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <input v-model="scenarioName" class="input-box m-0" style="width: 256px; font-size: 16px"
            placeholder="Enter scenario name" @input="getScenarioName($event)" />
        </b-col>
        <b-col>
          <div style="width: 330px" class="px-0 pb-5 d-flex justify-center input-types">
            <input type="file" id="actual-btn" ref="fileInput" accept=".csv" @change="onFileChange" hidden />
            <label class="file-label" for="actual-btn" v-if="seen">Choose File</label>
            <span id="file-chosen" ref="file-chosen" class="d-flex"
              style="justify-content: space-between; font-size: 15px">
              {{ file }}
              <label v-if="!seen" class="remove-label mr-5" @click="clearFileUploader">Remove</label>
            </span>
          </div>
        </b-col>

        <b-col>
          <w-button v-if="showNextConstrint" style="width: 206px; margin-right: 50px" :buttonLoader="'normal'"
            :buttonText="'Upload'" @buttonClicked="handleUploadTemplate">
          </w-button>
        </b-col>
        <b-col v-if="isViewDetails">
          <div class="d-flex flex-row">
            <div class="sliderIcon" @click="handleAdditionSceario">
              <div>
                <i class="fas fa-plus px-2" :style="{ color: rangeColor }"></i>
              </div>
            </div>
          </div>
        </b-col>
        <h3 class="d-none">
          Monthly min budget {{ formatValue(monthly_min_threshold) }}
        </h3>
        <h3 class="d-none">
          Monthly max budget {{ formatValue(monthly_max_threshold) }}
        </h3>
      </b-row>

      <div v-if="isViewDetails">
        <w-button style="width: 206px; float: right; margin-right: 40px" :buttonLoader="'normal'"
          :buttonText="'View Details'" @buttonClicked="handleViewDetails">
        </w-button>
      </div>
    </div>
    <page-loader v-if="isLoading" />
  </div>
</template>
<script>
// import RoundSlider from "vue-round-slider";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import Button from "@/components/Profile/Button.vue";
import Calendar from "@/widgets/Calendar.vue";
import PageLoader from "@/widgets/PageLoader.vue";
import Toast from "@/components/Toast/Toast.vue";

import { abbreviateNumber } from "js-abbreviation-number";

import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();

export default {
  name: "SalesGrowth",
  components: {
    // RoundSlider,
    "w-white-dropdown": WhiteDropdown,
    "w-button": Button,
    "w-calendar": Calendar,
    "page-loader": PageLoader,
    Toast,
  },
  props: {
    selectedBrandKPI: {
      type: String,
      default: "Tresemme",
    },
    sliderValue: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    bigRangeSize: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: "Forecasting",
    },
    handleColor: {
      type: String,
      default: "default",
    },
    sliderName: {
      type: String,
      default: "Sales Growth",
    },
    specialChar: {
      type: String,
      default: "M",
    },
    loader: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    activeTab: function () {
      // watch it
      this.getSummaries();
    },
    selectedBrandKPI: function () {
      this.getPreviousMonthlyBudget();
      this.getSummaries();
    },
  },
  computed: {
    parsePreviousBudget() {
      var value = require("num-words")(this.previousBudget);
      return value.toUpperCase();
    },
    sliderRadius() {
      const extraSmall = window.matchMedia("only screen and (max-width: 400px)");
      if (extraSmall.matches) {
        return "120";
      }
      const isMobile = window.matchMedia("only screen and (max-width: 576px)");
      if (isMobile.matches) {
        return "150";
      }
      if (this.bigRangeSize) {
        return "180";
      } else {
        return "200";
      }
    },
    sliderWidth() {
      const extraSmall = window.matchMedia("only screen and (max-width: 400px)");
      if (extraSmall.matches) {
        return "20";
      }
      const isMobile = window.matchMedia("only screen and (max-width: 576px)");
      if (isMobile.matches) {
        return "25";
      } else {
        return "35";
      }
    },
  },
  data() {
    return {
      haveAccessToThis: false,
      showSelectFromExistingScenario: false,
      bboseen: true,
      uploadBBOData: "",
      bboFile: "Upload file",
      showWarning: false,
      showOptimize: false,
      tableDataValues: {
        fields: [
          {
            key: "monthName",
            label: "monthName",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "monthlyBudget",
            label: "monthlyBudget",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      scenarioTotalBudget: "",
      scnearioNameDisplay: [],
      enterNewBudget: "",
      scenarioDates: [],
      selectedStartDate: {},
      scenarioDropdownDetails: [],
      scenarioSelectedDate: "",
      budgetFromScenario: "",
      max_threshold: 0,
      min_threshold: 0,
      monthly_max_threshold: 0,
      monthly_min_threshold: 0,
      previousBudget: localStorage.getItem("previousBudget"),
      forecastingData: "",
      isLoading: false,
      showNextConstrint: true,
      numOfScenario: 1,
      isViewDetails: false,
      todaysDate: "",
      scenarioName: "",
      uplodaData: "",
      seen: true,
      file: "Upload file",
      selectedForecastingDate: "",
      selectedBudgetOptimizationDate: "",
      showSummary: false,
      sliderValue1: 0,
      toastData: {
        show: false,
        type: "",
        message: "",
      },

      selectedBrand: {
        text: localStorage.getItem("selectedBrandKPI"),
        id: localStorage.getItem("selectedBrandKPI").toLowerCase(),
      },
      numOfWeeks: "",
      selectScenarioDuration: {},
      scenarioDurations: [],

      budgetDurations: [
        { id: "1", text: "1 month" },
        { id: "3", text: "3 months" },
        { id: "6", text: "6 months" },
        { id: "9", text: "9 months" },
        { id: "12", text: "12 months" },
      ],
      brandDropdown: [
        { text: "Tresemme", id: "tresemme" },
        { text: "Dove", id: "dove" },
      ],
      scenarioDetails: [
        {
          name: "scenario #1",
          scenarioName: "",
          fileChosen: {
            file: "",
            seen: true,
            fileData: "",
            uploadData: "",
          },
        },
        {
          name: "scenario #2",
          scenarioName: "",
          fileChosen: {
            file: "",
            seen: true,
            fileData: "",
            uploadData: "",
          },
        },
        {
          name: "scenario #3",
          scenarioName: "",
          fileChosen: {
            file: "",
            seen: true,
            fileData: "",
            uploadData: "",
          },
        },
      ],
      selectedBudgetDuration: { id: "1", text: "1 month" },
      selectedScenarioDropdown: { id: "Select", text: "Select" },
      totalDurations: [],
      totalBudgetSum: "",
      totalBudgetSplitSum: 0,
      validityText: "Entred Budget does not match the total budget",
    };
  },
  methods: {
    handleBBOptimization() {
      if (this.uploadBBOData !== "" && this.bboScenarioName.length !== 0) {
        this.toastData = {
          show: true,
          message: "Our system is running",
          type: "success",
        };
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.uploadBBOData);
        aimoUnileverService
          .uploadForcastingTemplate(
            formData,
            this.bboScenarioName,
            localStorage.getItem("selectedBrandKPI"),
            "Overall",
            1,
            sessionStorage.getItem("subId"),
          )
          .then(() => {
            // if(res.)
            // this.forecastingData = res;
            this.isLoading = false;
            this.showSelectFromExistingScenario = true;

            // this.isViewDetails = true;
            // this.showNextConstrint = false;

            this.toastData = {
              show: true,
              message: `Scenario ${this.bboScenarioName} is successfuly uploaded to our system`,
              type: "success",
            };

            this.bboScenarioName = "";
            this.$refs.uploadInput.value = null;
            this.bboFile = "No File chosen";
            this.bboseen = true;
            this.uploadBBOData = "";

            //get all summaries for scenario dropdown
            this.getSummaries();
          })
          .catch((err) => {
            this.showSelectFromExistingScenario = false;
            if (err.reason_phrase !== undefined) {
              this.toastData = {
                show: true,
                message: err.reason_phrase,
                type: "",
              };
            } else {
              this.toastData = {
                show: true,
                message: "Something went wrong, please try again",
                type: "",
              };
            }

            this.isLoading = false;
          });
      }
    },
    getbboScenarioName(e) {
      this.bboScenarioName = e.target.value;
    },
    clearUploader(e) {
      this.$refs.uploadInput.value = null;
      this.bboFile = "No file chosen";
      this.bboseen = true;
      this.uploadBBOData = "";
      e.preventDefault();
    },
    onFileUpload(event) {
      this.bboseen = false;
      var fileData = event.target.files[0];
      this.uploadBBOData = event.target.files[0];
      this.bboFile = "";
      this.bboFile = fileData.name;
    },
    checkValidations(e) {
      var x = e.months.filter(
        (u) =>
          u.monthlyBudget < this.monthly_max_threshold / 1000000 &&
          u.monthlyBudget > this.monthly_min_threshold / 1000000
      );

      x.length < e.months.length
        ? (this.showOptimize = false)
        : (this.showOptimize = true);

      this.showOptimize === false
        ? (this.showWarning = true)
        : (this.showWarning = false);

      this.showWarning === true
        ? (this.toastData = {
          show: "true",
          message: `   Budget for One or More months for the selected scenario is not in the
                    recommended range, please select a different scenario or upload new
                    budget.`,
          type: "",
        })
        : "";

      this.$emit("showOptimizeButton", this.showOptimize);
    },
    fetchScenarioData(e) {
      this.isLoading = true;
      aimoUnileverService
        .getSummaryDetails(e.id, sessionStorage.getItem("subId"))
        .then((res) => {
          aimoUnileverService.getBBOSplit(res).then((data) => {
            this.isLoading = false;

            this.scenarioTotalBudget = (Math.round(data.totalBudget * 100) / 100).toFixed(
              2
            );

            this.scnearioNameDisplay = [];

            this.tableDataValues.items = [];
            this.tableDataValues.items = data.months;

            this.checkValidations(data);

            const calBudget = data.totalBudget / data.months.length;

            let temp = [];

            for (var j = 0; j < data.months.length; j++) {
              temp = temp.concat(calBudget);
            }

            const budgetInfo = {
              months: data.months.length,
              budget: temp,
            };

            this.$emit("totalBudgets", budgetInfo);

            for (var i = 0; i < data.months.length; i++) {
              this.scnearioNameDisplay.push({
                name: data.months[i].monthName,
                budget: data.months[i].monthlyBudget,
              });
            }

            this.selectScenarioDuration = {
              id: data.months.length,
              text: `${data.months.length}`,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    selectStartDate(e) {
      this.selectedStartDate = e;
    },
    selectScenario(e) {
      this.selectedScenarioDropdown = e;
      this.fetchScenarioData(e);
    },
    getSummaries() {
      aimoUnileverService
        .getAllSummaries(localStorage.getItem("selectedBrandKPI"), "Overall", sessionStorage.getItem("subId"))
        .then((res) => {
          console.log("res", res);
          this.scenarioDropdownDetails = [];
          for (var i = 0; i < res.length; i++) {
            if (res[i].runId !== undefined) {
              this.scenarioDropdownDetails.push({
                text: res[i].scenario_name,
                id: res[i].runId,
              });
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    formatValue(data) {
      return abbreviateNumber(data, 1, {
        symbols: ["", "k", "M", "B", "T", "P", "E"],
      });
    },
    getPreviousMonthlyBudget() {
      let clientId = sessionStorage.getItem("subId")
      aimoUnileverService
        .getPreviousBudget(
          this.$parent.selectedBrand.text,
          this.selectedBudgetDuration.id,
          clientId
        )
        .then((res) => {
          this.max_threshold = res.overall_max_threshold;
          this.min_threshold = res.overall_min_threshold;
          this.monthly_max_threshold = res.monthly_max_threshold;
          this.monthly_min_threshold = res.monthly_min_threshold;
          this.previousBudget = this.formatValue(res.previous_months_budget);
        })
        .catch((err) => {
          alert(err);
        });
    },
    restartModel() {
      // if (e.preProcess.status === "error") {
      //   aiDigitalPoem.startPreProcess(e._id).then((res) => {
      //     console.log(res);
      //   });
      // } else if (e.attribution.status === "error") {
      //   aiDigitalPoem.startAttribution(e._id).then((res) => {
      //     console.log(res);
      //   });
      // } else if (e.optimization.status === "error") {
      //   aiDigitalPoem.startOptimization(e._id).then((res) => {
      //     console.log(res);
      //   });
      // }
      // var self = this;
      // setTimeout(function () {
      //   self.getTableData();
      // }, 5000);
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    handleAdditionSceario() {
      this.showNextConstrint = true;
      if (this.scenarioName.length !== 0) {
        this.numOfScenario += 1;
        (this.scenarioName = ""), (this.file = "No File chosen");
        this.seen = true;
        this.uplodaData = "";
      }
    },
    handleViewDetails() {
      this.$emit("tableData", this.forecastingData);
      this.$emit("viewDetails", localStorage.getItem("viewDetails"));
    },
    getTodaysDate() {
      let date = new Date();

      this.todaysDate = date;
    },
    getScenarioName(e) {
      this.scenarioName = e.target.value;
      // if (this.scenarioDetails[index] != e) {
      //   this.scenarioDetails[index].scenarioName = e.target.value;
      // }
    },
    handleUploadTemplate() {
      this.showNextConstrint = true;
      if (this.uplodaData !== "" && this.scenarioName.length !== 0) {
        this.toastData = {
          show: true,
          message: "Our system is running",
          type: "success",
        };
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.uplodaData);
        aimoUnileverService
          .uploadForcastingTemplate(
            formData,
            this.scenarioName,
            localStorage.getItem("selectedBrandKPI"),
            "Overall",
            this.numOfScenario,
            sessionStorage.getItem("subId"),
          )
          .then((res) => {
            // if(res.)
            this.forecastingData = res;
            this.isLoading = false;
            this.isViewDetails = true;
            this.showNextConstrint = false;

            this.toastData = {
              show: true,
              message: `Scenario ${this.scenarioName} is successfuly uploaded to our system`,
              type: "success",
            };

            this.scenarioName = "";
            this.$refs.fileInput.value = null;
            this.file = "No File chosen";
            this.seen = true;
            this.uplodaData = "";

            sessionStorage.setItem("viewDetails", true);
          })
          .catch((err) => {
            if (err.reason_phrase !== undefined) {
              this.toastData = {
                show: true,
                message: err.reason_phrase,
                type: "",
              };
            } else {
              this.toastData = {
                show: true,
                message: "Something went wrong, please try again",
                type: "",
              };
            }

            this.isLoading = false;
            this.isViewDetails = false;
            this.showNextConstrint = true;
            sessionStorage.setItem("viewDetails", false);
          });
      }
    },
    clearFileUploader(e) {
      this.$refs.fileInput.value = null;

      this.file = "No File chosen";
      this.seen = true;
      this.uplodaData = "";
      e.preventDefault();
    },
    onFileChange(event) {
      // this.scenarioDetails[index].fileChosen.seen = false;
      // this.scenarioDetails[index].fileChosen.fileData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.uploadData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.file = this.scenarioDetails[index].fileChosen.fileData

      this.seen = false;
      var fileData = event.target.files[0];
      this.uplodaData = event.target.files[0];
      this.file = fileData.name;
    },
    selectedWeeks(e) {
      this.numOfWeeks = e.target.value;
    },
    handleTemplateDownload() {
      let weeks = this.numOfWeeks;
      let startDate = this.formatDate(this.selectedForecastingDate);
      let brand = localStorage.getItem("selectedBrandKPI");
      let kpi = localStorage.selectedMediaKPI;
      let clientId = sessionStorage.getItem("subId");

      if (weeks.length !== 0 && startDate.length !== 0) {
        aimoUnileverService
          .downloadForcastingTemplate(brand, kpi, weeks, startDate, clientId)
          .then(() => { });
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    selectForecastingDate(e) {
      this.selectedForecastingDate = e;
    },
    selectBudgetOptimizationDate(e) {
      this.selectedBudgetOptimizationDate = e;
    },
    totalBudget(e) {
      this.totalBudgetSum = e.target.value;

      if (+e.target.value > this.max_threshold / 1000000) {
        this.toastData = {
          show: true,
          type: "",
          message: `Entred budget is more than ${this.formatValue(this.max_threshold)}`,
        };
      } else if (
        +e.target.value < this.min_threshold / 1000000 &&
        +e.target.value !== 0
      ) {
        this.toastData = {
          show: true,
          type: "",
          message: `Entred budget is less than ${this.formatValue(this.min_threshold)}`,
        };
      } else if (+e.target.value === 0) {
        console.log("");
      }

      e.target.value === null ? (this.totalBudgetSum = 1) : this.totalBudgetSum;

      const calBudget = +this.totalBudgetSum / this.selectedBudgetDuration.id;

      let temp = [];

      for (var j = 0; j < this.selectedBudgetDuration.id; j++) {
        temp = temp.concat(calBudget);
      }

      const budgetInfo = {
        months: this.selectedBudgetDuration.id,
        budget: temp,
      };

      this.totalBudgetSum !== "" && this.totalBudgetSum !== undefined
        ? (this.showOptimize = true)
        : (this.showOptimize = false);

      this.$emit("showOptimizeButton", this.showOptimize);

      this.$emit("totalBudgets", budgetInfo);
    },
    sliderChange(e) {
      this.$emit("sliderChangeHandle", e.value);
    },
    selectBudgetDuration(e) {
      this.selectedBudgetDuration = e;
      this.monthWiseBudgetInput();
      this.getPreviousMonthlyBudget();
    },

    monthWiseBudgetInput() {
      if (this.selectedBudgetOptimizationDate !== "") {
        var today = this.selectedBudgetOptimizationDate;
      } else {
        today = new Date();
      }
      const selectedDate = +this.selectedBudgetDuration.id;
      const currentDate = String(today.getMonth()).padStart(2);
      let year = today.getFullYear();
      // console.log(selectedDate, +currentDate, year);

      const yearsOnYears = (year) => {
        const years = [
          `Jan ${year}`,
          `Feb ${year}`,
          `Mar ${year}`,
          `Apr ${year}`,
          `May ${year}`,
          `Jun ${year}`,
          `Jul ${year}`,
          `Aug ${year}`,
          `Sep ${year}`,
          `Oct ${year}`,
          `Nov ${year}`,
          `Dec ${year}`,
        ];
        return years;
      };

      this.totalDurations = [];

      //can be optimized further
      switch (selectedDate) {
        case 1:
          this.totalDurations = [];
          var currentYear = yearsOnYears(year);
          // this.totalDurations.push({ name: currentYear[+currentDate], value: "" });
          break;
        case 3:
          this.totalDurations = [];
          currentYear = yearsOnYears(year);
          var nextYear = yearsOnYears(year + 1);
          for (var i = 0; i <= selectedDate - 1; i++) {
            this.totalDurations.push({
              name: currentYear[i + +currentDate],
              value: "",
            });
          }
          var invalidEntries = this.totalDurations.filter(
            (month) => month.name === undefined
          );

          for (var i1 = 0; i1 < invalidEntries.length; i1++) {
            this.totalDurations.push({ name: nextYear[i1], value: "" });
          }

          var reverseInvalidEntries = this.totalDurations.filter(
            (month) => month.name !== undefined
          );
          this.totalDurations = [];
          this.totalDurations.push(...reverseInvalidEntries);

          break;

        case 6:
          this.totalDurations = [];
          currentYear = yearsOnYears(year);
          nextYear = yearsOnYears(year + 1);
          for (var j = 0; j <= selectedDate - 1; j++) {
            this.totalDurations.push({
              name: currentYear[j + +currentDate],
              value: "",
            });
          }
          invalidEntries = this.totalDurations.filter(
            (month) => month.name === undefined
          );

          for (var j1 = 0; j1 < invalidEntries.length; j1++) {
            this.totalDurations.push({ name: nextYear[j1], value: "" });
          }

          reverseInvalidEntries = this.totalDurations.filter(
            (month) => month.name !== undefined
          );
          this.totalDurations = [];
          this.totalDurations.push(...reverseInvalidEntries);
          break;

        case 9:
          this.totalDurations = [];
          currentYear = yearsOnYears(year);
          nextYear = yearsOnYears(year + 1);
          for (var k = 0; k <= selectedDate - 1; k++) {
            this.totalDurations.push({
              name: currentYear[k + +currentDate],
              value: "",
            });
          }
          invalidEntries = this.totalDurations.filter(
            (month) => month.name === undefined
          );

          for (var k1 = 0; k1 < invalidEntries.length; k1++) {
            this.totalDurations.push({ name: nextYear[k1], value: "" });
          }

          reverseInvalidEntries = this.totalDurations.filter(
            (month) => month.name !== undefined
          );
          this.totalDurations = [];
          this.totalDurations.push(...reverseInvalidEntries);

          break;

        case 12:
          this.totalDurations = [];
          currentYear = yearsOnYears(year);
          nextYear = yearsOnYears(year + 1);
          for (var l = 0; l <= selectedDate - 1; l++) {
            this.totalDurations.push({
              name: currentYear[l + +currentDate],
              value: "",
            });
          }

          invalidEntries = this.totalDurations.filter(
            (month) => month.name === undefined
          );

          for (var l1 = 0; l1 < invalidEntries.length; l1++) {
            this.totalDurations.push({ name: nextYear[l1], value: "" });
          }

          reverseInvalidEntries = this.totalDurations.filter(
            (month) => month.name !== undefined
          );
          this.totalDurations = [];
          this.totalDurations.push(...reverseInvalidEntries);

          break;
      }
      // console.log(this.totalDurations);
    },

    sumOfBudgetSplit() {
      var budget = this.totalDurations;
      const budgetValues = budget.map((x) => +x.value);
      const sum = budgetValues.reduce((partialSum, a) => partialSum + a, 0);
      this.totalBudgetSplitSum = sum;

      sum > this.totalBudgetSum || sum < this.totalBudgetSum
        ? (this.showValidityText = true)
        : (this.showValidityText = false);

      // console.log("min", this.min_threshold)
      // console.log("max", this.max_threshold)

      // console.log(sum, this.min_threshold / 1000000)

      //     if(sum < (this.min_threshold / 1000000)) {
      //       this.validityText = `Entered budget is less than the ${this.formatValue(this.min_threshold)} budget`

      //     } else if (sum > (this.min_threshold / 1000000)) {
      //       console.log(this.max_threshold)

      //          this.validityText = `Entered budget is more than the ${this.formatValue(this.min_threshold)} budget`

      //     }

      const budgetInfo = {
        months: this.selectedBudgetDuration.id,
        budget: budgetValues,
        sum: sum,
        duration: budget,
      };

      this.$emit("totalBudgets", budgetInfo);
    },
    getClientDetails() {
      let client = sessionStorage.getItem("clientId");
      if (client !== "acko.datapoem@gmail.com") {
        this.haveAccessToThis = true;
        this.brandDropdown.push(
          {
            name: "Tresemme",
            id: "tresemme",
          },
          {
            name: "Dove",
            id: "dove",
          }
        );
      } else {
        this.haveAccessToThis = false;
        this.brandDropdown.push(
          { text: "Bengaluru", id: "Bengaluru" },
          { text: "Chennai", id: "Chennai" },
          { text: "Delhi", id: "Delhi" },
          { text: "Hyderabad", id: "Hyderabad" },
          { text: "Kolkata", id: "Kolkata" },
          { text: "Lucknow", id: "Lucknow" },
          { text: "Mumbai", id: "Mumbai" },
          { text: "Overall", id: "Overall" },
          { text: "Others", id: "Others" }
        );
      }
    },
  },
  beforeUpdate() {
    this.sliderValue1 = this.sliderValue;
  },
  created() {
    this.getSummaries();

    //  this.$nextTick(function() {
    //   window.setInterval(() => {
    // this.getPreviousMonthlyBudget();
    //     }, 5000);
    // });
    this.toastData = {
      show: true,
      type: "success",
      message:
        "Please use forecasting to upload the weekly data for all the features yourself",
    };

    this.sliderValue1 = this.sliderValue;
    this.monthWiseBudgetInput();
    this.getTodaysDate();
  },
};
</script>

<style scoped>
.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-check-input {
  height: 24px;
  /* margin: 68px 11px 18px 0;
  margin-top: 90px; */
  object-fit: contain;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: rgb(104, 222, 104);
  border-color: #14181e;
}

.Remember-me {
  width: 40%;
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  cursor: pointer;
  margin: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

.chart-row {
  margin-top: 100px;
}

.chart-row-short {
  margin-top: 70px;
}

.dataCard {
  max-width: 90%;
  width: 100%;
  min-height: 55px;
  margin-bottom: 100px;
  padding: 13px 9px 13px 15px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.dataCard a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.middleDatacard {
  margin-bottom: 31px;
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
  height: 240px;
}

.Last-year-title {
  font-family: ProximaNovaRegular;
  color: #727988;
  font-size: 16px;
}

.line {
  width: 1px;
  margin: 0 11px;
  border: solid 1px #c6ccd6;
}

.Last-year-value {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  color: #050505;
}

@media screen and (max-width: 768px) {
  .chart-row {
    margin-top: 30px;
  }

  .dataCard {
    margin-bottom: 30px;
  }
}
</style>
<style>
.rs-handle.rs-move {
  border: 11px solid #fff;
}

.rs-handle {
  background-color: var(--handleColor) !important;
}

.rs-handle.rs-focus {
  border: 11px solid #fff;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 15px;
  color: #222a37;
}

.input-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* Not Equal*/
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.input-box {
  font-family: ProximaNovaBold;
  color: #000;
  height: 60px;
  width: 120px;
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  background-color: rgb(239, 245, 255);
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.sliderIcon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: #f3f3f3;
}

.sliderIcon i {
  font-size: 20px;
}

:-ms-input-placeholder {
  color: #9aafd4;
}

::-ms-input-placeholder {
  color: #9aafd4;
}

.changeColor .disableButton {
  color: #fff !important;
}

.action {
  color: #000;
}

.container {
  height: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.step-indicator .stepactive::after {
  background: #050505;
}

.tab-panel.showConnectors {
  display: block;
}

.tab-panel {
  display: none;
}

.card {
  display: block;
}

.Use-product-data-to {
  height: 35px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #c9ced5;
}

.form-file-input {
  margin: 0px;
  padding: 15px;
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 5px;
  font-size: 20px;
  color: #9aafd4;
  border: 0;
  background-color: #eff5ff;
}

.choose-file-btn {
  width: 115px;
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  z-index: 2;
  border: none;
  top: 7px;
  height: 44px;
  cursor: pointer;
  color: #222a37;
  background-color: #eff5ff;
  transform: translateX(-39px);
  border: 2px solid #050505;
  font-size: 16px;
}

label.file-label {
  width: 115px;
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  border: none;
  top: 7px;
  height: 44px;
  cursor: pointer;
  color: #222a37;
  background-color: #eff5ff;
  transform: translateX(-39px);
  border: 2px solid #050505;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ProximaNovaRegular;
}

.remove-label {
  cursor: pointer;
  color: #ff5a5a;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ProximaNovaRegular;
}

#file-chosen {
  margin: 0px;
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 5px;
  font-size: 15px;
  color: #9aafd4;
  border: 0;
  background-color: #eff5ff;
  margin-left: 0.3rem;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.input-types {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

span#file-chosen {
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
}

span.d-flex.ProximaNovaSemiBold.p-3 {
  font-size: 18px !important;
  font-family: ProximaNovaRegular !important;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .row.product-data-section {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .input-types {
    position: relative;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .container-fluid {
    padding-left: 0 !important;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin-left: 18%;
  }
}

@media screen and (max-width: 768px) {
  .row.product-data-section {
    flex-direction: column;
    padding-bottom: 15px;
  }

  img.img.img-responsive {
    margin: auto;
  }

  .input-types {
    position: relative;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

@media screen and (max-width: 900px) {
  .input-types {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

@media screen and (max-width: 1024px) {
  #file-chosen {
    font-size: 15px !important;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

.download-channel-data {
  width: 100%;
  height: 20px;
  right: 0px;
  font-family: ProximaNovaRegular;
  font-size: 18px;
  font-weight: normal;
  position: relative;
  margin-top: 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  text-decoration: underline;
  /* color: blueviolet; */
}

.download-channel-data>a {
  cursor: pointer;
}
</style>
