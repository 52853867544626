<template>
  <b-modal hide-footer no-close-on-backdrop hide-header no-fade v-model="modalShow" size="xl" class="p-0">
    <div class="">
      <div class="w-100 p-0">
        <div class="row p-0">
          <div class="col pb-5 px-0 welcome ml-4 mt-4 align-start">
            <img src="@/assets/group-21.png" alt class="welcome--image" />
          </div>
          <div class="col px-5">
            <p class="title" style="font-size:25px;">Select Scenario to run Forecasting</p>
            <div>
              <div class="d-flex flex-row">
                <!-- <h4 class="mt-5">Select scenario to run</h4> -->
                <w-white-dropdown :options="scenarioDetails" :placeHolder="'Select'" :selectedOption="selectedScenario"
                  :labelText="'Scenario'" class="mr-3 mt-3" style="width:100%;" @input="selectScenario($event)">
                </w-white-dropdown>

                <div class="d-none">
                  <w-white-dropdown :options="forecastModelOptions" :placeHolder="'Select'"
                    :selectedOption="selectedForecastModel" :labelText="'Model'" class="mr-3 mt-3"></w-white-dropdown>
                </div>

              </div>
              <h3 class="mt-5">Your selected scenario is: <strong class="text-decoration-line: underline;">{{
                  selectedScenario.text
              }}</strong></h3>

              <w-button style="width: 256px" :buttonLoader="'normal'" :buttonText="'Predict'" class="my-5 mr-5"
                @buttonClicked="handlePredict">
              </w-button>

              <!---->
            </div>
            <div @click="close" class="change-mind">No, I changed my mind</div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import Dropdown from "@/widgets/Dropdown.vue";
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";

import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();

import moment from "moment";

export default {
  name: "Plan",
  components: {
    "w-white-dropdown": WhiteDropdown,
    // "w-dropdown": Dropdown,
    "w-button": Button,
  },
  data() {
    return {
      selectedScenario: "",
      selectedForecastModel: { text: "Last 3 months", id: 3 },
      forecastModelOptions: [{ id: 3, text: "Last 3 months" }],
      isActive: false,
      scenarioDetails: [],
      modalShow: false,
      saveResultName: "",
      userName: "",
      buttonText: "Save Model",
      planList: [
        {
          text: "Personal Space",
          id: "Personal Space",
        },
        {
          text: "Shared Folder",
          id: "Shared Folder",
        },
      ],
      modelList: [],
      userList: [
        {
          text: "Harish Natarajan",
          id: "user",
          iconHTML: '<i class="fa fa-user"></i>',
        },
      ],
      description: "",
      action: "",
      selectedDropdown: {},
    };
  },
  computed: {
    isDisabelModel() {
      if (this.action === "delete" || this.action === "deleteOptimized") {
        return false;
      } else if (this.action === "saveResult") {
        if (this.saveResultName !== "") {
          return false;
        }
        return true;
      } else {
        if (
          this.description !== "" &&
          // Object.keys(this.selectedDropdown).length !== 0 &&
          Object.keys(this.selectedModel).length !== 0
        ) {
          return false;
        }
      }

      return true;
    },
    disabled() {
      if (this.action === "delete") {
        return false;
      } else {
        if (this.selectedDropdown === "Select") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  created() {
    // this.getSummaries();
  },
  // mounted() {
  //   this.getSummaries()
  // },
  methods: {
    selectScenario(e) {
      this.selectedScenario = e;
    },
    handlePredict() {
      aimoUnileverService
        .getForecastingResults({
          runId: this.selectedScenario.id,
          brand: localStorage.getItem("selectedBrandKPI"),
          clientId: sessionStorage.getItem("subId"),
          kpi: "Overall",
        })
        .then((res) => {
          localStorage.setItem("optimizeData", JSON.stringify(res));
          sessionStorage.setItem("currentTab", "forecasting")
          sessionStorage.setItem("forecastingDropdown", JSON.stringify(this.scenarioDetails))
          sessionStorage.setItem("selectedForecast", JSON.stringify(this.selectedScenario))
          this.$router.push({
            name: "PlanningResults UL",
            params: {
              forecastedOptimizedData: res,
              scenarioDetails: this.scenarioDetails,
              selectedScenario: this.selectedScenario,
              isForecastActive: true,
            },
          });
        });
    },
    getSummaries() {
      aimoUnileverService.getAllSummaries(localStorage.getItem("selectedBrandKPI"), "Overall", sessionStorage.getItem("subId")).then((res) => {
        this.scenarioDetails = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i].runId !== undefined) {
            this.scenarioDetails.push({
              text: res[i].scenario_name,
              id: res[i].runId,
            });
          }
        }
      });
    },
    setScenario(obj) {
      this.selectedScenario = obj
    },
    open(obj) {
      this.userName = sessionStorage.getItem("userName");
      this.modalShow = true;
      this.action = obj.action;

      if (obj.action === "delete") {
        this.buttonText = "Delete Model";
      }
      if (obj.action === "deleteOptimized") {
        this.buttonText = "Delete Result";
      } else if (obj.action === "saveResult") {
        this.buttonText = "Save Result";
      } else if (this.action === "add") {
        this.modelList = obj.modelList;
        this.buttonText = "Save Model";
      }
      if (this.action === "share") {
        this.description = "Diwali Campaign";
      }
    },
    close() {
      this.modalShow = false;
    },
    handleSave() {
      if (this.action === "delete" || this.action === "deleteOptimized") {
        this.$emit("deleteModel");
      } else if (this.action === "saveResult") {
        this.$emit("saveResult", this.saveResultName);
      } else {
        var data = {
          runId: sessionStorage.getItem("runId"),
          modelName: this.description,
          // space: this.selectedDropdown.id.toLowerCase(),
          model: this.selectedModel.id,
          modelId: this.selectedModel.text,
          saveDate: moment(new Date()).format("YYYY-MM-DD"),
          // emailId: sessionStorage.getItem('clientId')
        };
        this.$emit("saveModel", data);
      }
      this.close();
    },
    selectDropdown(val) {
      this.selectedDropdown = val;
    },
    // selectModel(e) {
    //   this.selectedModel = e;
    // },
  },
};
</script>
<style>
.modal.show .modal-dialog {
  margin-top: 0px;
}
</style>
<style scoped>
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  color: #222a37;
  font-size: 16px;
  font-family: ProximaNovaBold;
}

.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}

input:focus {
  background-color: #eff5ff;
  outline: none;
}

.title {
  margin-top: 70px;
  font-size: 32px;
  font-family: ProximaNovaBold;
  line-height: 1.56;
  color: #222a37;
}

.subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  color: #9aafd4;
  margin-bottom: 24px;
}

.bg-red {
  background-color: #ff5a5a !important;
}

.change-mind {
  margin-top: 26px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #050505;
  text-decoration-line: underline;
  cursor: pointer;
}

.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 24px !important;
  object-fit: contain;
}

.btn-effect-disabled {
  opacity: 0.2;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
